@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/buttons";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

html {
    position: relative;
}

html,
body {
    background-color: #000;
    color: #ffffff;
    font-family: "Roboto";
    width: 100%;
    height: 100%;
}

article {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    align-items: stretch;

    main {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
        }

        p {
            text-align: center;
            margin: 20px 10px;
            max-width: 640px;
        }

        .btn-group {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .btn {
            border-radius: 5px;
            margin: 10px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        a {
            position: relative;
            text-decoration: none;
            color: white;
            text-transform: uppercase;
            padding: 10px;
            font-weight: bold;
            font-size: 14px;

            &:hover,
            &:active,
            &:visited {
                color: white;
            }
        }
    }
}
